@font-face {
  font-family: ModernSans;
  src: url("assets/ModernSans-Light.otf") format("opentype");
}

body {
  margin: 0;
  font-family: ModernSans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;

}

:root {
  --color-main: white;
  --color-second: black;
  --color-third: rgb(98, 231, 86);
  --line-size: 3px;
  --border-style: var(--line-size) solid var(--color-main);

  overflow-x: hidden;
}

:root::-webkit-scrollbar {
  display: none;
}