* {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
}

.App {
    color: var(--color-main);
}

@media screen and (max-width: 768px) {
    .Desktop {
        display: none;
    }
}

@media screen and (min-width: 769px) {
    .Mobile {
        display: none;
    }
}