.project-list {
    width: 100%;
    height: 40%;

    border-bottom: var(--line-size) solid var(--color-main);
    box-sizing: border-box;
}

div.project-list > .list {
    height: 100%;
    width: 1vw;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 0.8%;
}

.p-element {
    width: 160px;
    height: 160px;
    margin-bottom: 26px;
    margin-right: 26px;
    overflow: hidden;

    border: var(--border-style);
    background-color: black;

    z-index: 1;
    transition: ease-out 200ms;
}

.p-element:hover {
    transform-origin: center;
    transform: rotate(-45deg) scale(200%);

    border: var(--color-main) solid calc( var(--line-size) / 2);
    transition: ease-out 200ms;
    z-index: 1000;
}

.project-list:has(.p-element:hover) .p-element:not(:hover) {
    z-index: 0;
    scale: 0.9;
}

.p-element > .text {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 50%;

    font-size: 21px;

    transform-origin: center;
    transform: translateY(-50%) rotate(-45deg);
    transition: ease-out 200ms;
}

.p-element:hover > .text {
    margin: 5px;
    text-align: left;
    font-size: 15px;

    top: 0;
    transform: translateY(0%) rotate(0);

    transition: ease-out 200ms;
}

.p-element > .only-hov {
    opacity: 0;
    transform: translate(-100px, 0px);
}

.p-element:hover > .only-hov {
    opacity: 100%;
    transform: translate(0, 0);
    transition: cubic-bezier(.46,.64,.42,1.18) calc(500ms * var(--order));
}

.p-element .description {
    margin: 6px;
    font-size: 10px;
}

.p-element .stack {
    margin: 6px;
    display: flex;
    flex-wrap: wrap;
    padding-top: 70px;
    border-bottom: var(--color-main) solid calc( var(--line-size) / 2);
}

.stack > span {
    /* display: list-item;
    list-style-type: square; 
    list-style-position: inside;    */
    font-size: 7px;

    margin: -5px -10px 0 0;
    text-align: left;

    opacity: 0;
    transform-origin: top left;
    transform: rotate(-45deg);
}

.stack > span:before {
    content: "■ "
}

.p-element:hover span {
    opacity: 1;
    transition: opacity calc(900ms * var(--order-stack)) cubic-bezier(1,.27,.31,1);
}

.p-element > .bottom-bar {
    position: absolute;
    bottom: 5px;
    left: 5px;

    display: flex;
    justify-content: space-between;
    width: calc(99% - 10px);
}

.bottom-bar a {
    text-decoration: unset;
    color: var(--color-main);
    font-size: 13px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 50px;
    height: 15px;
    
    position: relative;
}

.bottom-bar a::after {
    content: "";
    width: 0px;
    height: 0px;

    position: absolute;
    z-index: -1;
    left: 0;
    bottom: -1px;
    opacity: 0;

    border-left: 1px solid var(--color-main);
    border-bottom: 1px solid var(--color-main);

    
    transition: opacity 0ms 200ms, width 200ms ease-in-out, height 200ms ease-in;
}

.bottom-bar a::before {
    content: "";
    width: 0px;
    height: 0px;

    position: absolute;
    z-index: -1;
    right: 0;
    top: -1px;
    opacity: 0;

    border-right: 1px solid var(--color-main);
    border-top: 1px solid var(--color-main);
    
    transition: opacity 0ms 200ms, width 200ms ease-in-out, height 200ms ease-in;
}

.bottom-bar a:hover::before, a:hover::after {
    width: calc(100% - 1px);
    height: 15px;
    opacity: 1;
    transition: opacity 0ms, width 200ms ease-in-out, height 200ms ease-in;
}