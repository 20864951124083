.desktop-display {
    --height-top-pos: 49vh;

    width: 100vw;
    height: 170vh;
    overflow: hidden;

    box-sizing: border-box;
    border: var(--border-style);
    border-bottom: unset;
}

.frame {
    content: "";
    display: block;
    pointer-events: none;

    width: 100vw;
    height: 100dvh;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 9999;

    box-sizing: border-box;
    border: var(--border-style);
}

/*
 SIDEBAR
*/

.sidebar {
    position: fixed;
    right: var(--line-size);
    top: var(--line-size);
    z-index: 2000;

    height: calc(100vh - calc(var(--line-size) * 2));
    margin-right: 10px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
}

div.sidebar div:has(a) {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}

div.sidebar a {
    font-size: 25px;
    position: relative;

    margin: 10px;
    padding: 3px;
    text-decoration: unset;
    color: var(--color-main);
}

div.sidebar a::after {
    content: "";
    width: 0%;
    height: 0%;

    position: absolute;
    z-index: -1;
    left: 0;
    bottom: -1px;
    opacity: 0;

    border-left: 2px solid var(--color-main);
    border-bottom: 2px solid var(--color-main);
    
    transition: opacity 0ms 200ms, width 200ms ease-in-out, height 200ms ease-in;
}

div.sidebar a::before {
    content: "";
    width: 0%;
    height: 0%;

    position: absolute;
    z-index: -1;
    right: 0;
    top: -1px;
    opacity: 0;

    border-right: 2px solid var(--color-main);
    border-top: 2px solid var(--color-main);
    
    transition: opacity 0ms 200ms, width 200ms ease-in-out, height 200ms ease-in;
}

div.sidebar a:hover::before, a:hover::after {
    width: calc(100% - 2px);
    height: 100%;
    opacity: 1;
    transition: opacity 0ms, width 200ms ease-in-out, height 200ms ease-in;
}

div.sidebar a:hover::after {
    width: calc(100% - 2px);
    height: 100%;
    opacity: 1;
    transition: opacity 0ms, width 200ms ease-in-out, height 200ms ease-in;
}

/*
 Main container
*/

.topleft-container {
    width: 35vw;
    height: var(--height-top-pos);
    position: absolute;
    z-index: 50;

    background-color: var(--color-second);
}

.content-bar {
    content: "";
    width: 80vh;
    height: 50vw;
    background-color: black;

    border-bottom: var(--border-style);

    position: absolute;
    z-index: -1;
    transform-origin: bottom left;
    translate: 0 -100%;
    transform: rotate(-70deg);
    left: calc(48vw - 12.5vw);
    top: calc(var(--height-top-pos) + .15vw);
}

.content-container {
    width: 4000px;
    height: 2000px;

    translate: calc(48vw - 12.5vw) var(--height-top-pos);

    transform-origin: top left;
    transform: rotate(45deg);

    border-top: var(--border-style);
    background-color: var(--color-second);

    --split-width-content: 8%;
}

div.content-container > .left {
    float: left;

    text-align: center;
    font-size: 40px;

    height: 100%;
    width: var(--split-width-content);
}

div.content-container > .right {
    height: 100%;
    width: calc(100% - var(--split-width-content));
    float: left;

    border-left: var(--border-style);
    box-sizing: border-box;
}

/*
*
* TOP CONTENT (Logo, description...)
*
*/

div.topleft-container > .content {
    padding-top: 60px;
    padding-left: 60px;
}

div.content > p:not(.title) {
    margin: 0;
    font-size: 20px;
}

p.title {
    font-size: 25px;
}

.three-container {
    position: absolute;
    right: 0;
    top: 0;

    width: 64vw;
    height: 170vh;

    background-color:  rgba(30, 30, 30, 0.1);
}

/* div.desktop-display > div:not(.frame, .three-container) {
    background-color: rgb(50, 50, 50);
} */